// core version + navigation, pagination modules:
import Swiper, { Navigation, Pagination, Autoplay, EffectFade } from 'swiper';

import Cookies from 'js-cookie'

import Modal from './components/Modal'

document.addEventListener('DOMContentLoaded', () => {
    /**
     * Code pour Google Analytics
     */

    if (Cookies.get('wmc_current_currency') && window.dataLayer) {
        window.dataLayer.push({
            currencyCode: Cookies.get('wmc_current_currency'),
        })
    }

    var mySwiper = new Swiper('.home-swiper-container', {
        modules: [Navigation, Pagination, Autoplay],
        // Optional parameters
        loop: true,

        // If we need pagination
        pagination: {
            el: '.home-swiper-pagination',
            type: 'bullets',
            renderBullet: function (index, className) {
                return (
                    '<span class="' + className + '"><strong></strong></span>'
                )
            },
            clickable: true,
        },
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },

        // Navigation arrow
        navigation: {
            nextEl: '.home-swiper-button-next',
            prevEl: '.home-swiper-button-prev',
        },
    })

    const myLogoSwiper = new Swiper('.logo-swiper-container', {
        modules: [Autoplay],
        // Optional parameters
        loop: true,
        autoHeight: true,
        centeredSlides: true,
        centeredSlidesBounds: true,
        freeMode: true,
        passiveListeners: true,

        autoplay: {
            delay: 3000,
            disableOnInteraction: true,
        },

        breakpoints: {
            190: {
              slidesPerView: 2,
              spaceBetween: 16,
              centeredSlides: false,
              centeredSlidesBounds: false,
            },
            320: {
              slidesPerView: 3,
              spaceBetween: 16,
            },
            782: {
              slidesPerView: 5,
              spaceBetween: 30,
            },
        },
    })

    const productSwiper = document.querySelectorAll('.product-swiper-container');
    productSwiper.forEach((element) => {
        var myProductSwiper = new Swiper(element, {
            modules: [Navigation, Pagination],
            // Optional parameters
            watchSlidesProgress: true,
            watchSlidesVisibility: true,
            loop: true,
    
            // If we need pagination
            pagination: {
                el: '.product-swiper-pagination',
                clickable: true,
            },
    
            // Navigation arrows
            navigation: {
                nextEl: '.product-swiper-button-next',
                prevEl: '.product-swiper-button-prev',
            },
        })
    });

    const productVideoSwiper = document.querySelectorAll('.product-videos-swiper-container');
    productVideoSwiper.forEach((element) => {
        var myProductVideosSwiper = new Swiper(element, {
            modules: [Pagination],
            watchSlidesProgress: true,
            watchSlidesVisibility: true,
            loop: true,
    
            // If we need pagination
            pagination: {
                el: '.product-swiper-pagination',
                clickable: true,
            },
            on: {
                slideChange: (event) => {
                    if(event.slides.length > 0) {
                        if(event.slides[event.activeIndex].querySelector('iframe').src == 'about:blank'){
                            event.slides[event.activeIndex].querySelector('iframe').src = event.slides[event.activeIndex].dataset.video;
                        }
                    }
                }
            },
        })
    });

    var myBlogSwiper = new Swiper('.blog-swiper-container', {
        modules: [Navigation, Pagination],
        // Optional parameters
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 32,
        centeredSlides: true,
        loop: true,

        // If we need pagination
        pagination: {
            el: '.blog-swiper-pagination',
            clickable: true,
        },

        // Navigation arrows
        navigation: {
            nextEl: '.blog-swiper-button-next',
            prevEl: '.blog-swiper-button-prev',
        },

        breakpoints: {
            0: {
                slidesPerView: 1,
            },
            600: {
                spaceBetween: 16,
            },
            768: {
                spaceBetween: 32,
            },
        },
    })

    var myTestimonialSwiper = new Swiper('.testimonial-swiper-container', {
        modules: [Navigation, EffectFade],
        // Optional parameters
        loop: true,
        effect: 'fade',
        fadeEffect: {
            crossFade: true,
        },

        // Navigation arrow
        navigation: {
            nextEl: '.testimonial-swiper-button-next',
            prevEl: '.testimonial-swiper-button-prev',
        },
    })

    var myMessageSwiper = new Swiper('.header-message-swiper-container', {
        modules: [Autoplay],
        // Optional parameters
        loop: true,
        // effect: 'fade',
        direction: 'vertical',
        // fadeEffect: {
        //     crossFade: true,
        // },

        autoplay: {
            delay: 10000,
            disableOnInteraction: false,
        },
    })

    // Accordions
    var acc = document.getElementsByClassName('accordion')
    var i

    for (i = 0; i < acc.length; i++) {
        acc[i].addEventListener('click', function () {
            this.classList.toggle('active')
            var panel = this.nextElementSibling
            if (panel.style.maxHeight) {
                panel.style.maxHeight = null
            } else {
                panel.style.maxHeight = panel.scrollHeight + 'px'
            }
        })
    }

    // Mobile menu
    const mobiletoggle = document.querySelector('.mobile-toggle')
    const navigationtoggle = document.querySelector('.navigation')

    if (mobiletoggle) {
        mobiletoggle.addEventListener('click', function () {
            this.classList.toggle('open')
            // navigationtoggle.classList.toggle('open')
            if (navigationtoggle.style.maxHeight) {
                navigationtoggle.style.maxHeight = null
            } else {
                navigationtoggle.style.maxHeight =
                    navigationtoggle.scrollHeight + 'px'
            }
        })
    }

    // Scrolling Anims
    // Detect request animation frame
    var scroll =
        window.requestAnimationFrame ||
        // IE Fallback
        function (callback) {
            window.setTimeout(callback, 1000 / 60)
        }
    var elementsToShow = document.querySelectorAll('.show-on-scroll')

    function loop() {
        Array.prototype.forEach.call(elementsToShow, function (element) {
            if (isElementInViewport(element)) {
                element.classList.add('is-visible')
            } else {
                element.classList.remove('is-visible')
            }
        })

        scroll(loop)
    }

    // Call the loop for the first time
    loop()

    // Helper function from: http://stackoverflow.com/a/7557433/274826
    function isElementInViewport(el) {
        // special bonus for those using jQuery
        if (typeof jQuery === 'function' && el instanceof jQuery) {
            el = el[0]
        }
        var rect = el.getBoundingClientRect()
        return (
            (rect.top <= 0 && rect.bottom >= 0) ||
            (rect.bottom >=
                (window.innerHeight || document.documentElement.clientHeight) &&
                rect.top <=
                (window.innerHeight ||
                    document.documentElement.clientHeight)) ||
            (rect.top >= 0 &&
                rect.bottom <=
                (window.innerHeight ||
                    document.documentElement.clientHeight))
        )
    }

    if (document.body.classList.contains('cart')) {
        $.getJSON(
            'https://api.ipgeolocation.io/ipgeo?apiKey=1a639598ec264c86b55d755ea9abd062',
        ).done(function (location) {
            if (location.continent_code === 'EU') {
                const warningMessage = document.querySelector(
                    '.warning-message.cart',
                )
                warningMessage.style.display = 'block'
            }
        })
    }

    if (document.body.classList.contains('woocommerce-checkout') || document.body.classList.contains('woocommerce-cart')) {

        if (document.body.classList.contains('woocommerce-checkout')) {
            setTimeout(() => {
                Modal()
            }, 5000)
        } else {
            Modal()
        }

    }
})
