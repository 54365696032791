const Modal = () => {
    const btns = document.querySelectorAll('.shipping-modal-btn')

    if (!btns) {
        return
    }

    btns.forEach(btn => {
        btn.addEventListener('click', activateBtn)
    })

    const closeBtn = document.querySelector('.modal-header .modal-close')

    if (!closeBtn) {
        return
    }

    closeBtn.addEventListener('click', closeModal)
}

export default Modal

function activateBtn(e) {
    e.preventDefault()

    const modal = document.querySelector('.modal-backdrop.shipping-modal')

    if (!modal) {
        return
    }

    modal.classList.toggle('modal-backdrop--is-visible')
}

function closeModal(e) {
    e.preventDefault()

    const modal = document.querySelector('.modal-backdrop.shipping-modal')

    if (!modal) {
        return
    }

    modal.classList.remove('modal-backdrop--is-visible')
}
